.detail-page,
.add-question-page {
  .title-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    @media (min-width: $small-breakpoint) {
      flex-flow: row;
    }

    .link-container {
      width: 100%;
      margin-bottom: 20px;

      @media (min-width: $small-breakpoint) {
        width: 33%;
        margin-bottom: 0;
      }
    }
    .default-link {
      cursor: pointer;
      svg {
        margin-right: 6px;
      }
      color: $eggplant-color;
      font-family: "metropolismedium";
      font-size: 1.1rem;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $teal-color;
        svg path {
          fill: $teal-color;
        }
      }
    }
    .heading-one {
      width: 100%;

      @media (min-width: $small-breakpoint) {
        width: 33%;
        margin-bottom: 0;
      }
    }
  }
}
.detail-page .answer-questions-banner {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  a {
    padding: 18px 20px;
    font-family: "metropolisbold";
    letter-spacing: 0.01em;
    font-size: 1.37rem;
    color: $white-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $darkpink-color;
    transition: all 0.17s;
    border-radius: 19px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.22);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: none;
    text-decoration: none;

    svg {
      margin-right: 9px;
    }

    &:hover,
    &:focus {
      background: $pink-color;
    }
  }

  @media (min-width: $large-breakpoint) {
    width: 40%;
    margin-bottom: 0;
  }
}

.detail-page {
  .unanswered-section {
    margin: 100px auto 30px auto;
    max-width: 1000px;

    .questions-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
    .question {
      width: 100%;
      padding: 30px 35px;
      border: solid 1px darken($cream-color, 10%);
      margin: 20px 10px;
      border-radius: 22px;
      min-height: 250px;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      position: relative;

      @media (min-width: $small-breakpoint) {
        width: 46%;
      }
      @media (min-width: $medium-breakpoint) {
        width: 30%;
      }

      &:hover,
      &:focus {
        border: solid 1px $eggplant-color;
        box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.22);

        .paragraph {
          color: $teal-color;
        }
        .record-question {
          visibility: visible;
          animation: appear 0.25s ease-in-out;
        }
      }

      .record-question {
        visibility: hidden;
        transform-origin: center;
        position: absolute;
        height: 100px;
        width: 100px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.79;

        svg {
          border-radius: 50%;
          box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
        }
      }

      .large-paragraph {
        margin-bottom: 8px;
        color: $eggplant-color;
        font-family: "metropolisbold";
        transition: all 0.2s ease-in-out;
      }

      .paragraph {
        color: lighten($darkgray-color, 20%);
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

.detail-page {
  .video-question-playlist-section {
    display: flex;
    flex-flow: column wrap;
    margin-top: 30px;
    margin-left: -25px;
    margin-right: -25px;
    max-width: 1600px;

    @media (min-width: $xlarge-breakpoint) {
      flex-flow: row;
      height: 52vh;
      min-height: 657px;
      max-height: 711px;
      margin-left: auto;
      margin-right: auto;
    }
    // ask jameson why this isn't overriding
    @media (min-width: $xxlarge-breakpoint) {
      height: 85vh;
    }
  }

  .video-question-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background: $eggplant-color;
    box-shadow: 0 5px 23px rgba(244, 161, 144, 0.22);
    overflow: hidden;

    @media (min-width: $xlarge-breakpoint) {
      width: 66%;
      border-radius: 42px;
    }

    .video-box {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .video-player {
        width: 100%;
        margin: 0;
        align-self: flex-start;
        min-height: 200px;
        position: relative;
      }

      .ba-videoplayer-container {
        height: auto !important;

        .ba-videoplayer-overlay,
        .ba-videoplayer-player-toggle-overlay {
          background: linear-gradient(
            0deg,
            #3e435e 0%,
            rgba(62, 67, 94, 0) 10.05%
          );
        }
      }

      .placeholder-overlay {
        background: linear-gradient(
          0deg,
          #3e435e 0%,
          rgba(62, 67, 94, 0) 20.05%
        );
        height: 100%;
        width: 100%;
        position: absolute;
      }

      .moving-placeholder {
        width: 100%;
        display: block;
      }

      .audio-placeholder {
        width: 100%;
        display: block;
      }

      .react-audio-player {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        height: 40px;
        width: 93%;
        margin: auto;
        border-radius: 50px;
        background-color: lighten($eggplant-color, 20%);
      }
    }

    .question-box {
      padding: 10px;
      display: flex;
      align-items: center;

      .form-section {
        width: 100%;
      }

      .form-group {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        @media (min-width: $medium-breakpoint) {
          flex-flow: row nowrap;
        }
      }

      .form-group input {
        border-radius: 50px;
        box-shadow: none;
        margin: 20px 20px;
        border: none;
        padding: 5px 20px;

        &:hover,
        &:focus {
          border: none;
          padding: 5px 20px;
        }

        @media (min-width: $medium-breakpoint) {
          margin: 0px 17px 20px 30px;
          margin-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .default-button.teal-rounded {
        margin: 0 20px 10px 20px;
        background: $teal-color;
        border-radius: 50px;
        box-shadow: none;
        white-space: nowrap;
        padding: 16px 30px;

        &:hover,
        &:focus {
          background-color: lighten($teal-color, 4%);
          transform: scale(1);
        }

        @media (min-width: $medium-breakpoint) {
          margin: 10px 30px 30px 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .playlist-container {
    width: 100%;
    margin-top: 30px;

    @media (min-width: $xlarge-breakpoint) {
      margin-top: 0px;
      width: 34%;
      height: 100%;
      overflow: scroll;
    }

    .playlist-item {
      cursor: pointer;
      transition: all 0.17s;
      display: flex;
      flex-flow: row;
      align-items: center;
      padding: 25px 25px 25px 20px;
      margin: 13px 35px;
      border: solid 1px darken($cream-color, 10%);
      border-radius: 22px;
      transition: 0.2s all ease-in-out;

      &:first-child {
        margin-top: 0 !important;
      }

      &:not(.selected):hover,
      &:not(.selected):focus {
        background-color: $white-color;
        box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.12);

        .icon-container .type-icon {
          display: none;
        }
        .icon-container .play-icon {
          visibility: visible;
          animation: expand 0.25s ease-in-out;
        }
      }

      &.selected {
        border: solid 1px $eggplant-color;
        background-color: $eggplant-color;
        box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.12);

        .large-paragraph {
          color: white;
        }
      }

      .icon-container {
        margin-right: 15px;
        width: 39px;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .type-icon {
          position: absolute;

          svg path {
            fill: $peach-color;
          }
        }
        .play-icon {
          visibility: hidden;
          svg path {
            fill: $teal-color;
          }
          svg circle {
            stroke: $teal-color;
          }
        }

        .brand-icon {
          position: absolute;
          // visibility: hidden;
        }
      }
    }
  }

  .add-set-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 50px;

    h2 {
      margin-bottom: 30px;
    }

    .more-button-row {
      .default-button {
        margin-top: 20px;
      }
    }
  }

  .add-family-section {
    width: calc(100% + 50px);
    display: flex;
    margin: 100px 0 0 -25px;
    backface-visibility: hidden;
    transition: all 0.2s ease-in-out;
    padding: 100px 30px;
    background: $lightpeach-color;
    text-decoration: none;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 30px;
    }

    .default-button {
      margin: 0;
    }
  }
}
