$darkgray-color: #2b2d34;
$eggplant-color: #3e435e;
$teal-color: #007e8a;
$darkpink-color: #e95e63;
$pink-color: #f37176;
$peach-color: #f4a190;
$lightpeach-color: #f8e2cd;
$cream-color: #fdf6ef;
$lightgray-color: #ededed;
$white-color: #fff;

$small-breakpoint: 577px;
$medium-breakpoint: 769px;
$large-breakpoint: 993px;
$xlarge-breakpoint: 1201px;
$xxlarge-breakpoint: 1440px;
