.root-page-container .ba-videorecorder-container,
.root-page-container .ba-videoplayer-container {
  font-family: metropolisbold;
  width: 100%;
  background-color: $eggplant-color;
  display: block;
}

// on videos where we don't want the user to see themselves
.hide-reflection .ba-recorder-overlay {
  background-color: $eggplant-color;
  transition: all 0.5s ease-in-out;
}

.hide-reflection-text {
  color: white;
  position: absolute;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  z-index: 2;
}

// Record Button
.ba-videorecorder-container
  .ba-recorder-theme-minimalist-dashboard
  .ba-videorecorder-button-primary {
  background-color: $darkpink-color;
  font-family: metropolisbold;
  color: $white-color;
  padding: 0.8rem 1.7rem;
  border-radius: 50rem;
  font-size: 1.2rem;
  backface-visibility: hidden;

  &:hover,
  &:focus {
    transform: scale(1.06) translateZ(0);
  }
}

// record/stop/redo button row
.ba-recorder-theme-minimalist-dashboard .ba-videorecorder-controlbar {
  margin-bottom: 0.9rem;
}

// the helpful hints that popover the video -- ex: Pick a covershot
.ba-recorder-overlay
  .ba-videorecorder-topmessage-container
  .ba-videorecorder-topmessage-message {
  font-family: metropolisbold;
  padding: 0.8rem 1.7rem;
  border-radius: 50rem;
  font-size: 1.2rem;
  letter-spacing: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

// redo button
.ba-videoplayer-overlay .ba-player-rerecord-bar {
  background-color: transparent;

  .ba-player-rerecord-frontbar {
    height: auto;
  }

  .ba-player-rerecord-button {
    background-color: $white-color;
    font-family: metropolisbold;
    color: $darkpink-color;
    padding: 0.8rem 1.7rem;
    border-radius: 50rem;
    font-size: 1.2rem;
    height: auto;
    letter-spacing: 0;
    margin-bottom: 0.6rem;
    line-height: unset;
  }
}

// container & message that overlays when the video is processing
.ba-videorecorder-container
  .ba-recorder-message-container
  .ba-recorder-top-inner-message-container,
.ba-videorecorder-container
  .ba-recorder-message-container
  .ba-recorder-second-inner-message-container,
.ba-videorecorder-container
  .ba-recorder-message-container
  .ba-recorder-third-inner-message-container,
.ba-videorecorder-container
  .ba-recorder-message-container
  .ba-recorder-fourth-inner-message-container {
  height: 100%;
  width: 100%;
}
.ba-videorecorder-container
  .ba-recorder-message-container
  .ba-recorder-top-inner-message-container.ba-recorder-long-message
  .ba-recorder-first-inner-message-container {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;

  .ba-recorder-message-message {
    color: $white-color;
    letter-spacing: 0;
    height: 100%;
    width: 100%;
    // keep an eye out for this flex, it replaced a default table-cell
    display: flex;
    justify-content: center;
    align-content: center;
    p {
      margin: 0;
      align-self: center;
    }
  }
}

// PLAYER

// the bar at the top of the player that has the volume, and expand
.root-page-container .ba-player-minimalist-theme-controlbar-header {
  background-color: transparent;
}

// the length of the video progress
.root-page-container .ba-player-minimalist-theme-progressbar-inner {
  background-color: lighten($eggplant-color, 10%);
  border-radius: 50px;
}

// the playing progress indication
.root-page-container .ba-player-minimalist-theme-progressbar-position,
.root-page-container .ba-player-minimalist-theme-progressbar-button {
  background: $darkpink-color;
}

.root-page-container .ba-player-minimalist-theme-time-container {
  background-color: transparent;
  color: $white-color;
  font-family: "metropolismedium";
  opacity: 1;
}

// the play/pause button
.root-page-container
  .ba-player-minimalist-theme-controlbar-top-block
  .ba-player-minimalist-theme-button-container
  .ba-player-minimalist-theme-button-inner {
  color: $white-color;
  text-shadow: 0 0 16px #000;
}
