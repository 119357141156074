@font-face {
  font-family: "vollkorn";
  src: url("../../assets/fonts/vollkorn-semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "metropolisbold";
  src: url("../../assets/fonts/metropolis-bold-webfont.woff2") format("woff2"),
    url("../../assets/fonts/metropolis-bold-webfont.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "metropolismedium";
  src: url("../../assets/fonts/metropolis-medium-webfont.woff2") format("woff2"),
    url("../../assets/fonts/metropolis-medium-webfont.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "metropolismedium";
  src: url("../../assets/fonts/metropolis-mediumitalic-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/metropolis-mediumitalic-webfont.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "metropolisregular";
  src: url("../../assets/fonts/metropolis-regular-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/metropolis-regular-webfont.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "metropolisregular";
  src: url("../../assets/fonts/metropolis-regularitalic-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/metropolis-regularitalic-webfont.woff")
      format("woff");
  font-weight: 600;
  font-style: italic;
}
