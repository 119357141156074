.onboarding.vertical-align-layout {
  padding: 50px 0;
}

.onboarding.add-family-member {
  .default-button {
    margin-bottom: 0;
  }
  .ghost-button {
    margin-top: 30px;
  }
}

.onboarding__heading-section {
  text-align: center;
  width: 100%;
  margin: 0 auto 40px auto;

  @media (min-width: $large-breakpoint) {
    max-width: 50%;
  }
}

.onboarding__video-section {
  width: 100%;
  max-width: 700px;
  margin: auto;
  background-color: $white-color;
  border-radius: 42px;
  border: 1px solid $lightpeach-color;
  overflow: hidden;
  box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.22);

  @media (min-width: $small-breakpoint) {
    max-width: 80%;
  }

  @media (min-width: $medium-breakpoint) {
    width: 80%;
  }

  @media (min-width: $large-breakpoint) {
    width: 50%;
  }

  .ba-videorecorder-container {
    border-radius: 0;
  }
}

.onboarding .tips-section {
  padding: 1.5rem;
  .heading-four {
    color: $teal-color;
  }
  .list li {
    margin-bottom: 0.8rem;
  }
}

.onboarding__button-section {
  text-align: center;
  width: 100%;
  margin: auto;

  @media (min-width: $large-breakpoint) {
    max-width: 50%;
  }
}

.expanding-tips-section {
  background: $lightgray-color;
  padding: 0 35px 20px 35px;
  cursor: pointer;

  a.click-zone {
    display: block;
    padding: 20px 0 0 0;
    margin-bottom: 0;
    color: $eggplant-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg path {
    fill: $eggplant-color;
  }

  a + ul {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s linear;
  }
  a:active + ul,
  a:focus + ul {
    max-height: 15em;
  }
  a:focus {
    pointer-events: none;
  }

  .list li {
    margin-top: 0.8rem;
  }
}
