.onboarding__question-section,
.detail-page__question-section,
.questions-page__question-section {
  margin: auto;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;

  @media (min-width: $medium-breakpoint) {
    width: 95%;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 1000px;
  }

  .card {
    min-width: 300px;
    max-width: 300px;
    width: 25%;
    height: 220px;
    border-radius: 42px;
    background: $white-color;
    border: solid 1px $eggplant-color;
    margin: 10px auto 40px auto;
    padding: 30px;
    position: relative;
    cursor: pointer;
    backface-visibility: hidden;
    box-shadow: 0px 2px 24px rgba(244, 161, 144, 0.2);
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    // transition: all 0.3s;

    &.added {
      background-color: $cream-color;
      cursor: auto;
    }

    &:not(.added):hover,
    &:not(.added):focus {
      // border-width: 2px;
      // padding: 29px;

      .add-question {
        visibility: visible;
        animation: appear 0.25s ease-in-out;
      }

      .card-shared {
        // border-width: 2px;

        &.first {
          bottom: -15px;
        }
        &.second {
          bottom: -30px;
        }
      }
    }

    @media (min-width: $medium-breakpoint) {
      height: 340px;
    }
  }

  .card .add-question {
    visibility: hidden;
    transform-origin: center;
  }

  .card.added .success,
  .card .add-question {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.79;

    svg {
      border-radius: 50%;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
    }
  }

  .card-shared {
    left: 50%;
    transform: translate(-50%);
    height: 50px;
    border: solid 1px $eggplant-color;
    background: $white-color;
    border-radius: 42px;
    position: absolute;
    transition: bottom 300ms ease-in-out;
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(244, 161, 144, 0.15);

    &.first {
      z-index: -1;
      bottom: -10px;
      width: 75%;
      opacity: 0.75;
    }

    &.second {
      z-index: -2;
      bottom: -20px;
      width: 50%;
      opacity: 0.3;
    }
  }

  .watermark-container {
    margin-bottom: 0.6rem;
    display: none;

    @media (min-width: $medium-breakpoint) {
      display: block;
    }
  }

  .question-label {
    font-family: "metropolisbold";
    font-size: 1.2rem;
    line-height: 1.9rem;
    color: $eggplant-color;
    margin: 0;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: $medium-breakpoint) {
      font-size: 1.46rem;
      line-height: 1.9rem;
      -webkit-line-clamp: 6;
    }
  }

  .count-label {
    margin: 0;
    font-family: "metropolismedium";
    color: lighten($darkgray-color, 45%);
    font-size: 0.9rem;

    @media (min-width: $medium-breakpoint) {
      font-size: 1.1rem;
    }
  }
}

// card overrides -- interviewee flow
.answer-question-page .onboarding__question-section {
  .card {
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
    padding: 0;
    cursor: default;

    &:hover,
    &:focus {
      border-width: 1px;
      padding: 0px;

      .card-shared {
        border-width: 1px;

        &.first {
          bottom: -20px;
        }
        &.second {
          bottom: -40px;
        }
      }
    }

    .card-shared {
      &.first {
        bottom: -20px;
      }
      &.second {
        bottom: -40px;
        height: 70px;
      }
    }

    .question-information-container {
      position: relative;

      .top-half {
        padding: 35px;
      }
    }

    .recording-container.video {
      position: relative;
      border-bottom-left-radius: 42px;
      border-bottom-right-radius: 42px;
      overflow: hidden;
    }

    .recording-container.audio {
      padding: 30px;

      .form-section {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }
  }
}

.answer-question-page .onboarding__question-section {
  .question-preview-container {
    position: absolute;
    z-index: 100;
    background: $eggplant-color;
    height: 100%;
    width: 100%;

    .next-question {
      padding: 10px 35px 20px 35px;
      animation: expand 0.5s ease-in-out;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 90%;

      .dots-loader {
        margin: 0 auto 30px auto;
        color: lighten($eggplant-color, 25%);
      }

      .next-question-text {
        color: $white-color;
      }

      p {
        color: lighten($eggplant-color, 43%);
        margin-bottom: 10px;
      }
    }
  }
}

@keyframes expand {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
}
@keyframes appear {
  from {
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
