.onboarding.welcome-page .page-content {
  display: flex;
  flex-flow: column;
  align-items: center;

  width: 100%;
  margin: auto;

  @media (min-width: $medium-breakpoint) {
    width: 80%;

    &.no-questions {
      width: 70%;
    }
  }

  &.no-questions {
    h2 {
      margin-bottom: 30px;
    }
    text-align: center;
    flex-flow: column;
  }
}

.onboarding.welcome-page .page-content {
  .onboarding__about-opus {
    width: 100%;
    max-width: 700px;

    @media (min-width: $medium-breakpoint) {
      width: 80%;
    }

    @media (min-width: $large-breakpoint) {
      width: 74%;
    }
  }
}

.onboarding.welcome-page {
  .onboarding__video-section {
    .top-half {
      margin: 30px;
      text-align: center;

      h2 {
        margin: 0;
      }
    }
  }
}

.onboarding__about-opus {
  text-align: center;

  &.title {
    margin-bottom: 30px;
  }

  &.body {
    margin-top: 30px;
  }
}

.recording-options.button-selection {
  display: flex;
  flex-flow: column;
  margin-top: 25px;
  align-items: center;

  .phone-answer {
    justify-content: center;
    margin-top: 12px;
    color: lighten($darkgray-color, 30%);

    &:hover,
    &:focus {
      color: $eggplant-color;

      svg .border {
        stroke: $eggplant-color;
      }
    }
  }
}

.answer-question-page .onboarding__question-section.audio {
  width: 100%;

  @media (min-width: $small-breakpoint) {
    width: 80%;
  }

  @media (min-width: $medium-breakpoint) {
    width: 60%;
  }

  @media (min-width: $large-breakpoint) {
    width: 40%;
  }
  @media (min-width: $xlarge-breakpoint) {
    width: 36%;
    max-width: 440px;
  }
}

.answer-question-page .onboarding__question-section.video {
  width: 100%;

  @media (min-width: $small-breakpoint) {
    width: 80%;
  }

  @media (min-width: $medium-breakpoint) {
    width: 60%;
  }

  @media (min-width: $large-breakpoint) {
    width: 40%;
  }
  @media (min-width: $xlarge-breakpoint) {
    width: 50%;
    max-width: 550px;
  }
}
.answer-question-page .permission-error-banner {
  width: 100%;
  margin: 0 auto 30px auto;
  padding: 30px;
  border-radius: 12px;
  border: solid 1px $peach-color;
  text-align: center;

  h4 {
    color: $darkpink-color;
  }

  @media (min-width: $medium-breakpoint) {
    width: 60%;
    max-width: 700px;
  }
}

.answer-question-page .requester {
  color: lighten($darkgray-color, 23%);
  margin-bottom: 0.6rem;
}

.answer-question-page .skip-section {
  margin-top: 20px;

  .default-link.gray {
    display: block;
    margin-top: 40px;
    font-size: 1.1rem;
    color: lighten($darkgray-color, 30%);

    &:hover,
    &:focus {
      color: $eggplant-color;
    }
  }
}
