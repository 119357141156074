.footer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 5px 25px;
  align-items: center;

  @media (min-width: $medium-breakpoint) {
    flex-flow: row;
    justify-content: space-between;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin-right: 30px;
  }

  li:last-child {
    margin-right: 0;
  }

  a,
  p {
    color: lighten($darkgray-color, 18%);
    font-size: 1rem;
    line-height: 1rem;
    margin: 5px 0;
  }

  a {
    text-decoration: none;
    &:hover,
    &:focus {
      color: $teal-color;
    }
  }
}
