@mixin bold-eggplant {
  font-family: "metropolisbold", sans-serif;
  color: $eggplant-color;
}

.white {
  color: $white-color !important;
}

.center {
  text-align: center;
}

.no-margin {
  margin: 0 !important;
}

.darkgray {
  color: $darkgray-color !important;
}

.heading-one {
  @include bold-eggplant;
  font-size: 1.9rem;
  margin: 0 0 20px 0;

  @media (min-width: $medium-breakpoint) {
    font-size: 2.375rem;
    line-height: 3.075rem;
  }
}

.heading-two {
  @include bold-eggplant;
  font-size: 1.55rem;
  line-height: 1.9rem;
  margin: 0 0 15px 0;

  @media (min-width: $medium-breakpoint) {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
}

.heading-three {
  @include bold-eggplant;
  font-size: 1.5rem;
  margin: 0 0 15px 0;
}

.heading-four {
  @include bold-eggplant;
  font-size: 1.3rem;
  line-height: 1.7rem;
  margin: 0 0 15px 0;
}

.large-paragraph {
  color: $darkgray-color;
  font-size: 1.312rem;
  line-height: 2rem;
  font-family: "metropolismedium";
  margin: 0;
}

.paragraph {
  color: $darkgray-color;
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-family: "metropolismedium";
  margin: 0;
}

.short-success {
  color: green;
  margin: 10px auto 10px auto;
}

.short-error {
  color: red;
  margin: 10px auto 10px auto;
}
