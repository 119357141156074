html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column nowrap;
}

.root-page-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin: 25px 25px;
}

.vertical-align-layout {
  justify-content: center;
}
