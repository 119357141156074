.list-page .hero {
  width: 100%;
  display: flex;
  margin: auto;
  border: solid 2px $lightpeach-color;
  backface-visibility: hidden;
  transition: all 0.2s ease-in-out;
  border-radius: 30px;
  box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.12);
  margin-bottom: 20px;
  padding: 2rem 3rem;
  text-decoration: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
  }

  @media (min-width: $large-breakpoint) {
    width: 90%;
  }

  &:hover,
  &:focus {
    transform: scale(1.01) translateZ(0);
    border: solid 2px $eggplant-color;
  }

  .default-button {
    margin: 0;
  }
}

.list-page .title {
  h1 {
    margin-bottom: 10px;
  }
}

.list-page .opus-grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: auto;
  margin-top: 30px;

  @media (min-width: $large-breakpoint) {
    width: 90%;
  }
}

.list-page .opus-container {
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  backface-visibility: hidden;
  outline: none;
  padding: 5px;

  @media (min-width: $small-breakpoint) {
    width: 50%;
  }

  @media (min-width: $large-breakpoint) {
    width: 33.3%;
  }

  &:hover,
  &:focus {
    transform: scale(1.04) translateZ(0);
  }

  .video-container,
  .add-opus-container {
    // min-height: 250px;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.22);

    .moving-placeholder {
      width: 100%;
      display: block;
    }

    .static-placeholder {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-image: url("../../assets/images/cover-loop-placeholder.png");
    }

    .name-container {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      padding: 1.5rem;
      width: 100%;
      background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.21) 0%,
        rgba(150, 100, 100, 0) 100%
      );
      h4 {
        margin: 0;
      }
    }
  }

  .add-opus-container {
    min-height: 140px;
    display: flex;
    flex-flow: column;
    position: relative;

    .name-container {
      z-index: 2;
    }

    video {
      position: absolute;
    }
  }
}
