@import "settings/fonts";
@import "settings/variables";
@import "settings/typography";
@import "elements/buttons";
@import "elements/circleLoader";
@import "elements/dotsLoader";
@import "elements/forms";
@import "elements/videos";
@import "elements/layouts";
@import "elements/navigation";
@import "elements/footer";
@import "elements/detailPage";
@import "elements/listPage";
@import "elements/questionCardDesigns";
@import "elements/onboardingInterviewer";
@import "elements/onboardingInterviewee";

@import "~modern-normalize/modern-normalize";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

body {
  margin: 0;
  font-family: "metropolisregular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $cream-color;
}

::selection {
  background: $peach-color;
}

svg {
  vertical-align: middle;
}
