a {
  text-decoration: none;
}

.default-link {
  cursor: pointer;
  color: $teal-color;
  text-decoration: none;
  transition: 0.2s all;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &.has-icon {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    svg {
      margin-right: 15px;
    }
  }
}

a .default-button,
.default-button {
  margin-top: 40px;
  padding: 18px 30px;
  cursor: pointer;
  background-color: $eggplant-color;
  letter-spacing: 0.01em;
  font-size: 1.37rem;
  line-height: 1.57rem;
  color: $white-color;
  font-family: "metropolisbold";
  border-radius: 19px;
  border: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.22);
  backface-visibility: hidden;
  outline: none;
  text-decoration: none;

  &.full-width {
    width: 100%;
  }

  &.no-margin {
    margin: 0;
  }

  &.has-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    svg {
      margin-right: 15px;
    }
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: scale(1.04) translateZ(0);
    text-decoration: none;
    background-color: $teal-color;
  }

  &:disabled {
    text-decoration: none;
    cursor: default;
    background-color: lighten($eggplant-color, 60%);
  }
}

a .ghost-button,
.ghost-button {
  margin-top: 40px;
  padding: 18px 30px;
  cursor: pointer;
  background-color: transparent;
  letter-spacing: 0.01em;
  font-size: 1.37rem;
  line-height: 1.57rem;
  color: $eggplant-color;
  font-family: "metropolisbold";
  border-radius: 19px;
  border: solid 1px $eggplant-color;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.22);
  backface-visibility: hidden;
  outline: none;

  &.full-width {
    width: 100%;
  }

  &.no-margin {
    margin: 0;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: scale(1.04) translateZ(0);
  }
}
