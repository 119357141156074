.navigation {
  box-sizing: border-box;
  // margin: 0px;
  min-width: 0px;
  display: flex;
  align-items: center;
  min-height: 3.75rem;
  justify-content: space-between;
  margin: 3px 25px;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin-right: 30px;
  }

  li:last-child {
    margin-right: 0;
  }

  a,
  p {
    color: $darkgray-color;
    font-size: 1.1rem;
    line-height: 1rem;
    margin: 5px 0;
  }

  a {
    text-decoration: none;
    &:hover,
    &:focus {
      color: $teal-color;
    }
  }
}
