.form-section {
  width: 100%;
  margin: auto;

  @media (min-width: $medium-breakpoint) {
    width: 55%;
  }

  @media (min-width: $large-breakpoint) {
    width: 33%;
  }

  .field-group {
    padding: 0;
    margin: 0 0 30px 0;

    &.button-section {
      text-align: center;
    }
  }

  .no-margin {
    margin: 0;
  }

  label {
    font-size: 1.19rem;
    line-height: 2rem;
    font-family: "metropolisbold";
    color: $eggplant-color;
    padding-top: 0;
    letter-spacing: 0.3px;
  }

  input,
  .react-tel-input input {
    width: 100%;
    font-size: 1.19rem;
    line-height: 2.8rem;
    border-radius: 19px;
    // border: 1px solid $lightpeach-color;
    border: 1px solid $eggplant-color;
    padding: 5px 12px;
    // box-shadow: 0px 5px 13px rgba(244, 161, 144, 0.22);
    box-sizing: border-box;
    font-family: "metropolismedium";
    color: $darkgray-color;
    outline: none;

    // overrides for just the number input field
    &.form-control {
      padding: 5px 12px 5px 53px;
      height: auto;
    }
  }

  input:hover,
  input:focus {
    border: 2px solid $eggplant-color;
    padding: 4px 11px;

    // overrides for just the number input field
    &.form-control {
      padding: 4px 11px 4px 52px;
    }
  }

  .field-sets {
    display: flex;
    flex-wrap: wrap;

    .field-group {
      width: 100%;

      @media (min-width: $small-breakpoint) {
        width: 50%;
      }
    }

    .field-group:first-of-type {
      padding-right: 0;
      @media (min-width: $small-breakpoint) {
        padding-right: 10px;
      }
    }
    .field-group:last-of-type {
      padding-left: 0;
      @media (min-width: $small-breakpoint) {
        padding-left: 10px;
      }
    }
  }
}

//flag selection on the phone input
.form-section .react-tel-input .flag-dropdown {
  border-radius: 13px;
  overflow: hidden;
  height: auto;
  top: 50%;
  bottom: unset;
  transform: translateY(-50%);
  border: solid 1px $cream-color;
  left: 8px;
  background: $cream-color;

  .selected-flag {
    height: 38px;
    padding: 0 0 0 11px;
    transition: 0.4s all;
  }

  &:hover .selected-flag,
  &:focus .selected-flag {
    background: $cream-color;
  }
}
